///include /assets/js/app/s/catalogue.js
///include /assets/js/app/s/basket.js
///include /assets/js/app/s/shared-settings.js
///include /assets/js/app/p/sbproduct/balance.js
///include /assets/js/app/s/suser.js
"use strict"

class VueHeader {
    /**
     *
     * @param {boolean} should_be_authenticated
     */
    init(should_be_authenticated) {
        this.view = new Vue({
            el: '#vue-header',
            name: 'header',
            data() {
                return {
                    balance: {},
                    basket: AnyBasket.inst,
                    /** @type {?{hasLogo: boolean}} */
                    //@ts-ignore
                    brand: brand,
                    /** @type {?string} */
                    brandLogoDataUrl: null,
                    catalogue: UserCatalogue.inst,
                    currency: null,
                    domainSearchText: "",
                    settings: SharedSettings.inst,
                    /** @type {?boolean} */
                    state: null,
                    user: SUser.inst,
                    paymentConfig: null,

                    get isBalanceEnabled() {
                        if (this.paymentConfig) {
                            const balance = this.paymentConfig.paymentGateways.find(pg => pg.type === "balance" && pg.isEnabled === true);

                            return balance ? true : false;
                        } else {
                            return false;
                        }
                    },
                    get products() {
                        if(this.catalogue.products) {
                            //@ts-ignore
                            return this.catalogue.products.filter(
                                p => p instanceof SBBalanceProduct && p.forSale
                            )
                        } else {
                            return []
                        }
                    },
                    get availableBalance() {
                        return this.balance && this.balance.availableBalance ? this.balance.availableBalance.toFixed(2) : 0.00;
                    },
                    get avatarURL() {
                        return this.settings.avatarURL
                    },
                    get basketItemCount() {
                        return(
                            this.basket.itemsFlat && this.basket.itemsFlat.length
                        )
                    },
                    get contact() {
                        return this.settings.contact
                    },
                    get currencyOptions() {
                        if( this.catalogue.config ){
                            var options = [];
                            if(
                                this.catalogue.config &&
                                this.catalogue.config.supportedCurrencies
                            ) {
                                for(const currency of Object.keys(this.catalogue.config.supportedCurrencies) ){
                                    if( Catalogue.currencies[currency] ){
                                        options.push({
                                            text: `${currency} (${Catalogue.currencies[currency].symbol})`,
                                            id: currency
                                        });
                                    }
                                }
                            }
                            return options.length > 1 ? options : null;
                        } else {
                            return null;
                        }
                    },
                    get userInitials() {
                        if(!this.contact) return null
                        if(!this.contact.person_name) return null
                        const parts = this.contact.person_name.split(/ /)
                        return parts[0].substring(0, 1) + parts[1].substring(0, 1)
                    },
                    get isExplicitStackUser() {
                        // is this.username && this.username.match(/@/) a better way to do this?
                        return this.user.user?.contact?.email;
                    },
                    get initialsBackground() {
                        var canvas = document.createElement("canvas");
                        canvas.width = 40;
                        canvas.height = 40;
                        var ctx = canvas.getContext("2d");
                        ctx.font = "bold 16px Comic Sans MS";
                        ctx.fillStyle = "white";
                        ctx.textAlign = "center";
                        ctx.fillText( this.userInitials, 20, 25);
                        return "url('"+canvas.toDataURL('image/png')+"')";
                    },
                    get hasDomainProducts() {
                        if(typeof SBDomainProduct != "undefined" && this.catalogue.products){
                            return this.catalogue.products && this.catalogue.products.some(p => p instanceof SBDomainProduct);
                        } else {
                            return null;
                        }
                    },
                    get hasTransferProducts() {
                        if( typeof SBDomainProduct != "undefined" && this.catalogue.products ){
                            return this.catalogue.products && this.catalogue.products.some(p => p instanceof SBDomainProduct && p.offerTransfer );
                        } else {
                            return null;
                        }
                    },
                    beespokeLabel(code) {
                        if( this.catalogue && this.catalogue.products ){
                            var bs = this.catalogue.products.find( p => p.code == code );
                            if( bs && bs.specifics ){
                                return bs.specifics.mainLabel;
                            } else {
                                return null;
                            }
                        } else {
                            return null;
                        }
                    },
                    async changeCurrency(currency) {
                        await this.basket.changeCurrency(currency);
                        location.reload();
                    }

                }
            },
            watch: {
                "catalogue.currency": function(c) {
                    this.currency = c;
                },
                currency(n,o) {
                    if( o && n && n != this.catalogue.currency ){
                        this.changeCurrency(n);
                    }
                }
            },
            async mounted() {
                if(this.brand && this.brand.hasLogo) {
                    try {
                        await $.ajax({
                            method: "HEAD",
                            url: "/brandLogo?" + Math.random()
                        })
                        this.brandLogoDataUrl = "/brandLogo?" + Math.random()
                    } catch(e) {
                        //
                    }
                }

                await Promise.all([
                    this.catalogue.preloadSingle("products"),
                    this.user.preloadSingle("identityInfo")
                ]);

                await this.loadBalance()

                this.$watch(
                    () => this.catalogue.failedProperties.userVATRate,
                    v => {
                        if(v) {
                            ShowNotification.fail("Unable to offer purchase for VAT reasons")
                            if( window.location.pathname != '/ordering-not-available' ){
                                window.location = "/ordering-not-available";
                            }
                        }
                    }
                )

                if(should_be_authenticated) {
                    if(sessionStorage.navState !== undefined) {
                        this.state = !!parseInt(sessionStorage.navState)
                    } else {
                        sessionStorage.navState = 0
                        this.state = false
                    }
                }

                this.paymentConfig = await $.ajax(`/n/payment/config`);
            },
            methods: {
                /**
                 * Load a user's balance
                 *
                 * @returns {Promise<*>}
                 */
                 async loadBalance() {
                    const pi = await $.ajax({
                        method: "get",
                        url: `/n/payment/paymentItem`,
                        data: {
                            selector: {
                                owner: `${this.user.identityInfo.content}`,
                            },
                            fields: [
                                "display",
                                "owner",
                                "type",
                                "id",
                                "isBillable",
                                "availableNonPayable",
                                "availablePayable",
                                "availableBalance"
                            ]
                        }
                    })
                    this.balance = pi.find(i => i.type === "balance")
                },
                logout() {
                    if(window.sessionStorage) {
                        sessionStorage.clear()
                    }
                    location.href = "/logout?r=" + (window.logoutUrl ? window.logoutUrl : location.host === 'stackcp.com' ? "/login" : "/basket-summary-login");
                },
                shoppingBasket() {
                    $(".shopping-basket").fadeToggle( "fast");
                },
                searchForDomain() {
                    location.href =
                        "/domain-search?" +
                        $.param({domain: this.domainSearchText})
                }
            },
            components: {
                vueSelect: VueSelect,
            },
        })
    }
}
